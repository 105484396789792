import React, {useEffect, useState} from "react";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {GetReportData} from "../../../services/report";
import {useSegmentStore} from "../../../stores/useSegmentStore";
import {useTrendStore} from "../../../stores/useTrendStore";
import {RoundOff} from "../../../utils/math";

const Constants = {
    TREND_KEYS: {
        x: 'date',
        y: 'Energy Saved'
    },
}

const TrendContent: React.FC = () => {

    const segment = useSegmentStore(state => state.selected);
    const trendRange = useTrendStore(state => state.range);
    const [trendData, setTrendData] = useState<any[]>([]);

    useEffect(() => {
        GraphData();
    }, [segment, trendRange]);

    const GraphData = async () => {
        if (!segment || !trendRange) return;
        const responseData = await GetReportData({
            reportType: 'energy',
            segmentId: segment.id,
            dateFrom: trendRange.from,
            dateUpto: trendRange.upto,
        });
        try {
            const data: any[] = [];
            Object.keys(responseData.data.segmentDeviceData.diff).forEach(date => {
                let actual: number = 0;
                Object.values(responseData.data.segmentDeviceData.diff[date]).forEach((currActual) => {
                    actual += parseFloat(currActual + '');
                });
                const currData: any = {date};
                currData[Constants.TREND_KEYS.y] = RoundOff(actual);
                data.push(currData);
            });
            setTrendData(data);
        } catch (e) {
        }
        console.log(responseData.data);
    }

    const CustomTooltip = (params: { active: boolean, payload: any, label: string }) => {
        if (params.active && params.payload && params.payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${params.label} : ${params.payload[0].value}`}</p>
                    <p className="desc">Anything you want can be displayed here.</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="widget" style={{backgroundColor: 'rgba(240, 240, 240, 0.9)', minHeight: '70vh'}}>
            {segment && (
                <ResponsiveContainer className="widget-graph">
                    <LineChart data={trendData} defaultShowTooltip={true}>
                        <XAxis dataKey={Constants.TREND_KEYS.x}/>
                        <YAxis/>
                        <Line
                            type="monotone"
                            dataKey={Constants.TREND_KEYS.y}
                            stroke="#8884d8"
                            //strokeDasharray="5 5"
                        />
                        <CartesianGrid stroke="#bbb" strokeDasharray="5 5"/>
                        <Tooltip/>
                        <Legend/>
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default TrendContent;
