import {Button, Input, Modal, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {useEffect, useState} from "react";
import BtnRound from "../../../atoms/buttons/round";
import {ApiResponse, ApiResponseType} from "../../../../types/api";
import {AdminGetConfigs, AdminUpdateConfig} from "../../../../services/admin/config";

interface AdminConfig {
    key: React.Key;
    id: number;
    level: 'M' | 'S' | 'G' | 'D';
    identifier?: string;
    param: {
        key: string;
        value: string;
    }
}

const AdminSectionConfigs: React.FC = () => {

    const [data, setData] = useState<[]>([]);
    const [modalUpdate, setModalUpdate] = useState<boolean>(false);

    const [config, setConfig] = useState<AdminConfig>();
    const [inpParamValue, setInpParamValue] = useState<string>();

    useEffect(() => {
        fetchConfigs();
    }, []);

    const fetchConfigs = async () => {
        const res: any = await AdminGetConfigs();
        if (res && res.data) {
            setData(res.data);
        }
    };

    const updateConfig = async () => {
        if (!config || !inpParamValue) return;
        const res: ApiResponse = await AdminUpdateConfig(config.id + "", {
            paramValue: inpParamValue,
        });
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchConfigs();
            setModalUpdate(false);
            resetStates();
        }
    };

    const resetStates = () => {
        setConfig(undefined);
        setInpParamValue(undefined);
    }

    const columns: ColumnsType<AdminConfig> = [
        {
            title: "Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "ascend",
        },
        {
            title: "Parameter",
            dataIndex: ['param', 'key'],
            sorter: (a, b) => a.param.key.localeCompare(b.param.key),
        },
        {
            title: "Value",
            dataIndex: ['param', 'value'],
            sorter: (a, b) => a.param.value.localeCompare(b.param.value),
        },
        {
            title: "Actions",
            render: (config: AdminConfig) => {
                return (
                    <>
                        <BtnRound
                            label={() => <i className="fa fa-edit"/>}
                            className="warning"
                            selected={false}
                            onClick={() => {
                                setConfig(config);
                                setInpParamValue(config.param.value);
                                setModalUpdate(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <div className="configs-section">
            <div className="mt-3">
                <BtnRound
                    label="Master"
                    selected={false}
                    onClick={() => {
                    }}/>
            </div>
            <div className="table-wrapper mt-2">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        pageSize: 8,
                    }}
                />
            </div>

            <Modal
                title={`Update Configuration`}
                open={modalUpdate}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalUpdate(false);
                        setConfig(undefined);
                        setInpParamValue(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={updateConfig}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group mt-3 mb-4">
                    <label>{config?.param.key}</label>
                    <Input
                        placeholder="Enter Parameter Value"
                        value={inpParamValue}
                        onChange={(e: any) => setInpParamValue(e.target.value)}
                    />
                </div>
            </Modal>
        </div>
    );
};
export default AdminSectionConfigs;
