import "./app.scss";
import {Route, Routes} from "react-router-dom";
import Authenticate from "./components/pages/login/authenticate";
import LoginHandler from "./components/pages/login";
import {ConfigProvider} from "antd";
import {useEffect} from "react";
import EnvConfig from "../env";
import {AppConfigs} from "./configs/app";

const App = () => {
    useEffect(() => {
        console.log(EnvConfig);
        document.title = AppConfigs.ORG_NAME;
    }, []);
    return (
        <div className="body-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: "#333",
                                colorPrimaryBg: "#eee",
                                colorPrimaryBgHover: "#eee",
                                colorPrimaryActive: "#ccc",
                                colorPrimaryBorder: "#ccc",
                                colorPrimaryBorderHover: "#ccc",
                            },
                        }}
                    >
                        <Routes>
                            <Route path="/authenticate" Component={Authenticate}/>
                            <Route path="/*" Component={LoginHandler}/>
                        </Routes>
                    </ConfigProvider>
                </div>
            </div>
        </div>
    );
};
export default App;
