import {Input, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {GetReportDownload} from "../../services/report";

const ReportSelector = () => {

    const [modal, setModal] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<string>();
    const [dateUpto, setDateUpto] = useState<string>();

    useEffect(() => {
        setDefaultDates();
    }, []);

    const setDefaultDates = () => {
        const d1 = new Date();
        const d2 = new Date();
        d1.setDate(1);
        d2.setMonth(d2.getMonth() + 1);
        d2.setDate(0);
        setDateFrom(d1.toISOString().split('T')[0]);
        setDateUpto(d2.toISOString().split('T')[0]);
    };

    return (
        <>
            <div
                className="item"
                title="Report"
                onClick={() => setModal(true)}
            >
                <i className="fa fa-file"/>
            </div>
            <Modal
                title={`Download Reports`}
                className="admin-panel"
                open={modal}
                closable={true}
                onCancel={() => setModal(false)}
                width={360}
                footer={[]}
            >
                <div className="form-group">
                    <label>Date From</label>
                    <Input
                        type='date'
                        placeholder="Select Date"
                        value={dateFrom}
                        onChange={(e: any) => setDateFrom(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Date Upto</label>
                    <Input
                        type='date'
                        placeholder="Select Date"
                        value={dateUpto}
                        onChange={(e: any) => setDateUpto(e.target.value)}
                    />
                </div>
                {dateFrom && dateUpto && (
                    <div className="form-group text-center">
                        <a
                            className="btn btn-sm btn-dark"
                            target='_blank'
                            href={GetReportDownload({reportType: 'energy', dateFrom, dateUpto})}
                            onClick={() => {
                                setTimeout(() => {
                                    setModal(false);
                                }, 1000);
                            }}>
                            Energy Report &nbsp;
                            <i className="fa fa-arrow-right"/>
                        </a>
                    </div>
                )}
            </Modal>
        </>
    );

};
export default ReportSelector;
