import React from "react";
import {Button} from "antd";

interface Props {
    label: string | React.FC;
    className?: string;
    selected: boolean;
    onClick: () => void;
    title?: string;
}

const BtnRound: React.FC<Props> = (props) => {
    return (
        <Button
            className={`btn-round${props.selected ? " selected" : ""}${
                props.className ? " " + props.className : ""
            }`}
            type={props.selected ? "primary" : "default"}
            shape="round"
            onClick={props.onClick}
            title={props.title ?? undefined}
        >
            {typeof props.label === "string" ? props.label : <props.label/>}
        </Button>
    );
};
export default BtnRound;
