import axios, {AxiosError, AxiosResponse} from "axios";
import ApiConfigs from "../configs/api";

export const GetDevices = async (segmentId: string, groupId: string) => {
    const token = localStorage.getItem("token");
    return axios
        .get(
            `${ApiConfigs.CORE_SERVER_URL}/segment/${segmentId}/group/${groupId}/device`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
export const GetDevicesStatus = async (segmentId: string, groupId: string) => {
    const token = localStorage.getItem("token");
    return axios
        .get(
            `${ApiConfigs.CORE_SERVER_URL}/segment/${segmentId}/group/${groupId}/device-status`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
export const GetDeviceData = async (deviceId: string, range: { from: string, upto: string }) => {
    const token = localStorage.getItem("token");
    return axios
        .post(
            `${ApiConfigs.CORE_SERVER_URL}/device/${deviceId}/data`,
            {range},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const DevicesOff = async () => {
    const token = localStorage.getItem("token");
    return axios
        .get(`${ApiConfigs.CORE_SERVER_URL}/device/off`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
