import React from "react";
import {Dropdown} from "antd";
import {DeviceOptionMenuItem} from "../../../../types/device";

interface Props {
    deviceId: string;
    onItemSelected: (item: { deviceId: string, itemKey: DeviceOptionMenuItem }) => void;
}

export const DeviceOption = (props: Props) => {

    const optionMenu: { label: string, key: DeviceOptionMenuItem }[] = [
        {
            label: 'Device Trend',
            key: DeviceOptionMenuItem.TRENDS,
        }
    ];

    return (
        <div className="device-options">
            <Dropdown
                menu={{
                    items: optionMenu,
                    onClick: (item: { key: string }) => props.onItemSelected({
                        deviceId: props.deviceId,
                        itemKey: item.key as DeviceOptionMenuItem,
                    })
                }}
                trigger={['click']}
            >
                <i className="fa fa-ellipsis-v"/>
            </Dropdown>
        </div>
    );
}
