import React, {useState} from "react";
import BtnRound from "../../atoms/buttons/round";
import {useUserStore} from "../../../stores/useUserStore";
import {LoginService} from "../../../services/user";
import {AppConfigs} from "../../../configs/app";
import {Alert} from "antd";

const Login: React.FC = () => {
    const status = useUserStore((state) => state.status);
    const setStatus = useUserStore((state) => state.setStatus);

    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>("");

    const [error, setError] = useState<string>();

    const onLogin = async () => {
        if (!username) return;
        const userRes: { users: any[] } | null = await LoginService(username, password);
        console.log(userRes);
        if (userRes && userRes.users && userRes.users[0]) {
            localStorage.setItem("token", userRes.users[0].token);
            window.location.reload();
        } else {
            setStatus("failure");
            setError('Could not login. Please check credentials');
            setTimeout(() => setError(undefined), 3000);
        }
    };

    return (
        <>
            {status === "init" && (
                <div className="col-md-6 col-lg-3 col-xl-3 login-wrapper">
                    <div className="col-md-12 login">
                        <div className="header">
                            Logging in to the <strong>{AppConfigs.ORG_NAME}</strong>
                        </div>
                    </div>
                </div>
            )}
            {status === "failure" && (
                <div className="col-md-6 col-lg-3 col-xl-3 login-wrapper">
                    <div className="col-md-12 login">
                        <div className="header">
                            {AppConfigs.ORG_LOGO && (
                                <img
                                    src={AppConfigs.ORG_LOGO}
                                    alt={AppConfigs.ORG_NAME + " - Logo"}
                                />
                            )}
                            <strong>{AppConfigs.ORG_NAME}</strong>
                        </div>
                        <div className="form-group">
                            <label>Username</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Username"
                                onChange={(e: any) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Enter Password"
                                onChange={(e: any) => setPassword(e.target.value)}
                            />
                        </div>
                        {error && (
                            <div className="form-group">
                                <Alert type='error' message={error}/>
                            </div>
                        )}
                        <div className="form-group text-center">
                            <div className="button-wrapper">
                                <BtnRound
                                    label="Login Here"
                                    selected={true}
                                    onClick={onLogin}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;
