import {create} from "zustand";
import {User} from "../types/user";

type UserStatus = "init" | "success" | "failure";

interface UserState {
    status: UserStatus;
    setStatus: (status: UserStatus) => void;
    user: User | null;
    setUser: (user: User) => void;
    getToken: () => string | null;
    logout: () => void;
}

export const useUserStore = create<UserState>((set, get) => ({
    status: "init",
    setStatus: (status: UserStatus) => set({status}),
    user: null,
    setUser: (user: User) => set({user}),
    getToken: () => localStorage.getItem("token"),
    logout: () => {
        localStorage.removeItem("token");
        window.location.href = "/login";
    },
}));
