import {Button, Input, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {useTrendStore} from "../../stores/useTrendStore";

const TrendRangeSelector = () => {

    const [modal, setModal] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<string>();
    const [dateUpto, setDateUpto] = useState<string>();


    const range = useTrendStore(state => state.range);
    const setRange = useTrendStore(state => state.setRange);

    useEffect(() => {
        if (!range) {
            setDefaultDates();
        }
    }, []);

    useEffect(() => {
        if (range) {
            setDateFrom(range.from);
            setDateUpto(range.upto)
        }
    }, [range]);

    const setDefaultDates = () => {
        const d1 = new Date();
        const d2 = new Date();
        d1.setDate(1);
        d2.setMonth(d2.getMonth() + 1);
        d2.setDate(0);
        setRange({
            from: d1.toISOString().split('T')[0],
            upto: d2.toISOString().split('T')[0],
        });
    };

    return (
        <>
            {range && (
                <>
                    <Button onClick={() => setModal(true)}>
                        <i className="fa fa-calendar"/> &nbsp;
                        <strong>{range.from}</strong>
                        &nbsp;to&nbsp;
                        <strong>{range.upto}</strong>
                    </Button>
                    <Modal
                        title={``}
                        className="admin-panel"
                        open={modal}
                        closable={true}
                        onCancel={() => setModal(false)}
                        width={360}
                        footer={[
                            <Button onClick={() => setModal(false)}>
                                <i className="fa fa-times"/> &nbsp; Cancel
                            </Button>,
                            <Button type="primary" onClick={() => {
                                setRange({
                                    from: dateFrom + '',
                                    upto: dateUpto + '',
                                });
                                setModal(false);
                            }}>
                                Submit &nbsp;
                                <i className="fa fa-arrow-right"/>
                            </Button>,
                        ]}
                    >
                        <div className="form-group">
                            <label>Date From</label>
                            <Input
                                type='date'
                                placeholder="Select Date"
                                value={dateFrom}
                                onChange={(e: any) => setDateFrom(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Date Upto</label>
                            <Input
                                type='date'
                                placeholder="Select Date"
                                value={dateUpto}
                                onChange={(e: any) => setDateUpto(e.target.value)}
                            />
                        </div>
                    </Modal>
                </>
            )}
        </>
    );

};
export default TrendRangeSelector;
