import {AdminSectionType, useAdminStore} from "../../../stores/useAdminStore";
import BtnRound from "../../atoms/buttons/round";
import Greeting from "../../atoms/greeting";
import AdminSectionDevices from "./sections/devices";
import AdminSectionGroups from "./sections/groups";
import AdminSectionSegments from "./sections/segments";
import React from "react";
import AdminSectionUsers from "./sections/users";
import AdminSectionConfigs from "./sections/configs";

const AdminPanel: React.FC = () => {
    const section = useAdminStore((state) => state.section);
    const setSection = useAdminStore((state) => state.setSection);

    const Menu: React.FC<{ label: string; section: AdminSectionType }> = (
        props
    ) => {
        return (
            <div className="menu">
                <BtnRound
                    label={props.label}
                    selected={section === props.section}
                    onClick={() => setSection(props.section)}
                />
            </div>
        );
    };

    return (
        <>
            <div className="col-md-12 col-lg-12 col-xl-11 admin-panel">
                <div className="content-wrapper">
                    <Greeting/>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 col-lg-3 col-xl-2 p-4">
                                <div className="section-wrapper">
                                    <Menu label="Buildings" section="segments"/>
                                    <Menu label="Rooms" section="groups"/>
                                    <Menu label="Devices" section="devices"/>
                                    <Menu label="Users" section="users"/>
                                    <Menu label="Master" section="master"/>
                                </div>
                            </div>
                            <div className="col-md-8 col-lg-9 col-xl-10 p-4">
                                <div className="section-wrapper">
                                    <div className="heading">
                                        <h5>
                                            {section === "segments" && "Buildings"}
                                            {section === "groups" && "Rooms"}
                                            {section === "devices" && "Devices"}
                                            {section === "users" && "Users"}
                                            {section === "users" && "Master"}
                                        </h5>
                                    </div>
                                    <div className="body">
                                        {section === "segments" && <AdminSectionSegments/>}
                                        {section === "groups" && <AdminSectionGroups/>}
                                        {section === "devices" && <AdminSectionDevices/>}
                                        {section === "users" && <AdminSectionUsers/>}
                                        {section === "master" && <AdminSectionConfigs/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AdminPanel;
