export interface WebSocketConfig {
    url: string;
    //topics: [];
    on: {
        open?: (instance: WebSocketConnection) => void;
        send?: (topic: string, data: string) => void;
        message?: (topic: string, data: string) => void;
        close?: () => void;
    };
}

export interface WebSocketMessage {
    topic: string;
    data: any;
}

export class WebSocketConnection {
    private connection: WebSocket | undefined;

    constructor(config: WebSocketConfig) {
        this.initialise(config);
    }

    send(topic: string, data: any) {
        this.connection?.send(this.encodeMessage(topic, data));
    }

    private initialise(config: WebSocketConfig) {
        this.connection = new WebSocket(config.url);
        this.connection.onopen = () => {
            if (this.connection && config.on.open) config.on.open(this);
        };
        this.connection.onmessage = (e) => {
            if (config.on.message) {
                const message: WebSocketMessage = this.decodeMessage(e.data);
                config.on.message(message.topic, message.data);
            }
        };
        this.connection.onclose = () => {
            if (config.on.close) {
                config.on.close();
            }
            setTimeout(() => {
                this.initialise(config);
            }, 1000);
        };
        this.connection.onerror = (err) => {
            console.error("Socket Error", err);
            if (this.connection) this.connection.close();
        };
    }

    private encodeMessage(topic: string, data: any): string {
        return topic + "::" + JSON.stringify(data);
    }

    private decodeMessage(encMessage: string): WebSocketMessage {
        const msg: string[] = encMessage.trim().split("::");
        return {
            topic: msg[0],
            data: JSON.parse(msg[1]),
        };
    }
}
