import {useSegmentStore} from "../../stores/useSegmentStore";
import React, {useState} from "react";
import {useUserStore} from "../../stores/useUserStore";
import {Button} from "antd";

const SegmentControl: React.FC = () => {

    const user = useUserStore((state) => state.user);
    const segment = useSegmentStore((state) => state.selected);
    const showSegment = useSegmentStore((state) => state.setModal);
    const resetSegments = useSegmentStore((state) => state.reset);

    const [modalConfig, setModalConfig] = useState<boolean>(false);
    const [inpConfigDataLog, setInpConfigDataLog] = useState<"Y" | "N">('N');
    const [inpConfigIsEnabled, setInpConfigIsEnabled] = useState<"Y" | "N">('N');

    // const updateConfigs = async () => {
    //     if (!inpConfigDataLog || !inpConfigIsEnabled) return;
    //     const res: ApiResponse = await AdminUpdateDeviceConfigs('3' + "", {
    //         configs: {
    //             dataLog: inpConfigDataLog,
    //             isEnabled: inpConfigIsEnabled,
    //         },
    //     });
    //     if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
    //         setModalConfig(false);
    //         setInpConfigDataLog('N');
    //         setInpConfigIsEnabled('N');
    //         resetSegments();
    //     }
    // };

    return (
        <div className="header-segment">
            <Button onClick={() => showSegment(true)}>
                <i className="fa fa-home"/> &nbsp;
                {segment?.label}
            </Button>

            {user && user.type === 'admin' && (
                <>
                    {/*<button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => setModalConfig(true)}>*/}
                    {/*    <i className="fa fa-cog"/>*/}
                    {/*</button>*/}
                    {/*<Modal*/}
                    {/*    title={`Device Configuration`}*/}
                    {/*    open={modalConfig}*/}
                    {/*    closable={false}*/}
                    {/*    width={360}*/}
                    {/*    footer={[*/}
                    {/*        <Button onClick={() => {*/}
                    {/*            setModalConfig(false);*/}
                    {/*            setInpConfigDataLog(undefined);*/}
                    {/*            setInpConfigIsEnabled(undefined);*/}
                    {/*        }}>*/}
                    {/*            <i className="fa fa-times"/> &nbsp; Cancel*/}
                    {/*        </Button>,*/}
                    {/*        <Button type="primary" onClick={updateConfigs}>*/}
                    {/*            Submit &nbsp;*/}
                    {/*            <i className="fa fa-arrow-right"/>*/}
                    {/*        </Button>,*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    {ModuleConfigs.ENABLE_DEVICE_LOGGING && (*/}
                    {/*        <div className="row mt-4">*/}
                    {/*            <div className="col-8">Log Device Data</div>*/}
                    {/*            <div className="col-4 text-right">*/}
                    {/*                <Switch*/}
                    {/*                    size="small"*/}
                    {/*                    checked={inpConfigDataLog === "Y"}*/}
                    {/*                    onChange={(checked: boolean) =>*/}
                    {/*                        setInpConfigDataLog(checked ? "Y" : "N")*/}
                    {/*                    }*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    <div className="row mt-4">*/}
                    {/*        <div className="col-8">Enable Device</div>*/}
                    {/*        <div className="col-4 text-right">*/}
                    {/*            <Switch*/}
                    {/*                size="small"*/}
                    {/*                checked={inpConfigIsEnabled === "Y"}*/}
                    {/*                onChange={(checked: boolean) =>*/}
                    {/*                    setInpConfigIsEnabled(checked ? "Y" : "N")*/}
                    {/*                }*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="row mt-1">*/}
                    {/*        <div className="col-12">&nbsp;</div>*/}
                    {/*    </div>*/}
                    {/*</Modal>*/}
                </>
            )}
        </div>
    );
};
export default SegmentControl;
