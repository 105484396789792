import React from "react";
import SegmentControl from "../../molecules/segmentControl";
import TrendRangeSelector from "../../molecules/trendRangeSelector";

const TrendHeader: React.FC = () => {

    return (
        <>
            <div className="widget" style={{background: 'rgba(240, 240, 240, 0.9)'}}>
                <TrendRangeSelector/>
                <span className="d-inline-block float-right">
                    <SegmentControl/>
                </span>
            </div>
        </>
    );
};

export default TrendHeader;
