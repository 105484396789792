import React from "react";
import {useUserStore} from "../../stores/useUserStore";
import {useScreenStore} from "../../stores/useScreenStore";
import {useNavigate} from "react-router-dom";
import {useSegmentStore} from "../../stores/useSegmentStore";
import {AppConfigs} from "../../configs/app";
import ReportSelector from "./reportSelector";
import LogoutSelector from "./logoutSelector";

const Navbar: React.FC = () => {
    const navigateTo = useNavigate();

    const screen = useScreenStore((state) => state.screen);
    const setScreen = useScreenStore((state) => state.setScreen);

    const user = useUserStore((state) => state.user);

    const segment = useSegmentStore((state) => state.selected);
    const showSegments = useSegmentStore((state) => state.setModal);

    return (
        <div className="nav-wrapper col-lg-12 col-xl-1">
            <div className="nav">
                <div className="logo-wrapper">
                    <div className="logo" style={{backgroundImage: `url(${AppConfigs.ORG_LOGO})`}}>&nbsp;</div>
                </div>
                <div className="item-wrapper">
                    <div
                        className={`item${screen === "segments" ? " active" : ""}`}
                        title="Segments"
                        onClick={() => {
                            setScreen("segments");
                            navigateTo("/segments");
                            if (!segment) {
                                showSegments(true);
                            }
                        }}
                    >
                        <i className="fa fa-home"/>
                    </div>
                    <div
                        className={`item${screen === "trends" ? " active" : ""}`}
                        title="Trends"
                        onClick={() => {
                            setScreen("trends");
                            navigateTo("/trends");
                        }}
                    >
                        <i className="fa fa-bar-chart"/>
                    </div>
                    <ReportSelector/>
                    {user?.type === "admin" && (
                        <div
                            className={`item${screen === "admin" ? " active" : ""}`}
                            title="Admin Panel"
                            onClick={() => {
                                setScreen("admin");
                                navigateTo("/admin");
                            }}
                        >
                            <i className="fa fa-cogs"/>
                        </div>
                    )}
                    <LogoutSelector/>
                </div>
            </div>


        </div>
    );
};

export default Navbar;
