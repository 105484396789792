import axios, {AxiosError, AxiosResponse} from "axios";
import ApiConfigs from "../configs/api";

export const LoginService = async (username: string, password: string) => {
    const unameRes = await axios
        .post(ApiConfigs.CORE_SERVER_URL + "/auth", {
            type: 'UNAME',
            key: username,
        })
        .then((res: AxiosResponse) => res.data.data)
        .catch((error: AxiosError) => null);
    if (unameRes && unameRes.hashCode) {
        return await axios
            .post(ApiConfigs.CORE_SERVER_URL + "/auth/otp?scope=user", {
                hashCode: unameRes.hashCode,
                otp: password,
            })
            .then((res: AxiosResponse) => res.data.data)
            .catch((error: AxiosError) => null);
    }
    return null;
};

export const ValidateUserTokenService = async () => {
    const token = localStorage.getItem("token");
    return axios
        .get(ApiConfigs.CORE_SERVER_URL + "/auth/validate/user", {
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data.data;
        })
        .catch((error: AxiosError) => {
            return null;
        });
};
