import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";

import "./assets/css/bootstap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/index.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );
root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
