import axios, {AxiosError, AxiosResponse} from "axios";
import ApiConfigs from "../configs/api";

export const GetGroups = async (segmentId: string) => {
    const token = localStorage.getItem("token");
    return axios
        .get(`${ApiConfigs.CORE_SERVER_URL}/segment/${segmentId}/group`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
