import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

const Authenticate: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token: string | null = searchParams.get("token");
        if (token) {
            localStorage.setItem("token", token);
            setTimeout(() => {
                window.location.href = "/";
            }, 1500);
        }
    }, []);

    return (
        <div className="login-wrapper">
            <div className="login">
                <div className="header">
                    Authenticating <strong>Smarden</strong> ...
                </div>
            </div>
        </div>
    );
};
export default Authenticate;
