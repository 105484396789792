import {Modal} from "antd";
import BtnRound from "../atoms/buttons/round";
import {useNavigate} from "react-router-dom";
import {useScreenStore} from "../../stores/useScreenStore";
import {useSegmentStore} from "../../stores/useSegmentStore";
import {Segment} from "../../types/segment";
import {useGroupStore} from "../../stores/useGroupStore";
import {useUserStore} from "../../stores/useUserStore";
import {useDeviceStore} from "../../stores/useDeviceStore";

const SegmentSelector: React.FC = () => {
    const navigateTo = useNavigate();

    const user = useUserStore((state) => state.user);
    const setScreen = useScreenStore((state) => state.setScreen);

    const modal = useSegmentStore((state) => state.modal);
    const setModal = useSegmentStore((state) => state.setModal);
    const data = useSegmentStore((state) => state.data);
    const selected = useSegmentStore((state) => state.selected);
    const setSelected = useSegmentStore((state) => state.setSelected);

    const fetchGroups = useGroupStore((state) => state.fetch);
    const resetGroups = useGroupStore((state) => state.reset);

    const resetDevices = useDeviceStore((state) => state.reset);

    const logout = useUserStore((state) => state.logout);

    return (
        <Modal
            title={`Choose Office Building`}
            open={modal}
            closable={selected !== null}
            onCancel={() => {
                if (selected) {
                    setModal(false);
                }
            }}
            width={360}
            footer={[]}
        >
            <div className="home-selector-wrapper">
                <div className="home-selector">
                    {data.map((currSegment: Segment) => {
                        return (
                            <div className="home-btn-wrapper">
                                <BtnRound
                                    label={currSegment.label}
                                    selected={selected?.id === currSegment.id}
                                    onClick={async () => {
                                        resetGroups();
                                        resetDevices();
                                        setSelected(currSegment);
                                        fetchGroups(currSegment.id);
                                        setModal(false);
                                        //setScreen("segments");
                                        //navigateTo("/segments");
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            {!selected && user?.type === "admin" && (
                <div className="home-selector-logout">
                    <BtnRound
                        label={() => (
                            <div>
                                <i className="fa fa-users"/> &nbsp; Admin Panel
                            </div>
                        )}
                        selected={true}
                        onClick={() => {
                            setScreen("admin");
                            navigateTo("/admin");
                        }}
                    />
                </div>
            )}
            {!selected && (
                <div className="home-selector-logout">
                    <BtnRound
                        label={() => (
                            <div>
                                <i className="fa fa-arrow-left"/> &nbsp; Logout
                            </div>
                        )}
                        selected={true}
                        onClick={logout}
                    />
                </div>
            )}
        </Modal>
    );
};
export default SegmentSelector;
