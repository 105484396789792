import React, {useState} from "react";
import deviceOffline from "../../../../assets/images/devices/offline.png";
import {Tag, Tooltip} from "antd";
import {DeviceOption} from "./common/option";
import {useDeviceOptionStore} from "../../../stores/useDeviceOptionStore";
import {DeviceTemperature} from "../../../types/device/list/temperature";

const DeviceCardTemperature: React.FC<
    DeviceTemperature & { onClick: () => void }
> = (props) => {

    const status: 'on' | 'off' = props.params.status === 1 ? 'on' : 'off';
    const temperature: string = parseFloat(props.params.temperature ?? '0').toFixed(2);

    const [tooltip, setTooltip] = useState<boolean>(false);
    const setTrendModal = useDeviceOptionStore(state => state.setTrendModal);

    return (
        <div
            className="col-sm-12 col-md-4 col-lg-4 col-xl-3"
            style={{display: "inline-block"}}
        >
            <div
                className={`device ${props.configs.isEnabled === "N" || !props.isOnline ? "offline" : ""}`}
                onClick={() => {
                    if (props.configs.isEnabled === "Y") {
                        props.onClick();
                    } else {
                        setTooltip(!tooltip);
                        setTimeout(() => {
                            setTooltip(false);
                        }, 2000);
                    }
                }}
            >
                {props.configs.isEnabled === "N" && (
                    <div className="disabled">
                        <Tag color="error" title={props.comment}>Disabled</Tag>
                    </div>
                )}
                {props.configs.isEnabled === "N" && (
                    <Tooltip
                        title="Device disabled. Contact Admin"
                        open={tooltip}
                        placement="topLeft"
                    ></Tooltip>
                )}
                <DeviceOption
                    deviceId={props.id}
                    onItemSelected={props.onOptionItemSelected}
                />
                <div
                    className={`device-badge device-type-temperature ${status}`}
                    style={{fontSize: "1.6rem", color: "#666"}}
                >
                    {!props.isOnline && <img src={deviceOffline} alt={props.label}/>}
                    {props.isOnline && status === "off" && <i className="fa fa-bolt"/>}
                    {props.isOnline && status === "on" && <>{temperature}&deg;</>}
                </div>
                {props.comment && (
                    <div className="disabled" style={{color: '#999', cursor: "pointer"}}>
                        <i className="fa fa-info-circle" title={props.comment}/>
                    </div>
                )}
                <div className="label">{props.label}</div>
            </div>
        </div>
    );
};

export default DeviceCardTemperature;
