import {create} from "zustand";

type Screen = "trends" | "graph" | "segments" | "admin";

interface ScreenState {
    screen: Screen;
    setScreen: (screen: Screen) => void;
}

export const useScreenStore = create<ScreenState>((set, get) => ({
    screen: "segments",
    setScreen: (screen: Screen) => set({screen}),
}));
