import TrendContent from "./content";
import TrendHeader from "./header";
import React from "react";
import SegmentSelector from "../../molecules/segmentSelector";

const Trends: React.FC = () => {
    return (
        <>
            <div className="col-md-12 col-lg-12 col-xl-11">
                <div className="widget-wrapper">
                    <TrendHeader/>
                    <TrendContent/>
                </div>
            </div>
            <SegmentSelector/>
        </>
    );
};
export default Trends;
