import {create} from "zustand";

interface DeviceOptionState {
    trendModal: boolean;
    setTrendModal: (selector: boolean) => void;
}

export const useDeviceOptionStore = create<DeviceOptionState>((set, get) => ({
    trendModal: false,
    setTrendModal: (trendModal: boolean) => set({trendModal}),
}));
