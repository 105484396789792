import {create} from "zustand";

export type AdminSectionType =
    | "segments"
    | "groups"
    | "devices"
    | "users"
    | "master";

interface AdminState {
    section: AdminSectionType;
    setSection: (section: AdminSectionType) => void;
}

export const useAdminStore = create<AdminState>((set, get) => ({
    section: "segments",
    setSection: (section: AdminSectionType) => set({section}),
}));
