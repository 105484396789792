import {Button, Dropdown, Input, Modal, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {useEffect, useState} from "react";
import BtnRound from "../../../atoms/buttons/round";
import {AdminGetSegments} from "../../../../services/admin/segment";
import {ApiResponse, ApiResponseType} from "../../../../types/api";
import {AdminAddGroup, AdminDeleteGroup, AdminGetGroups, AdminUpdateGroup,} from "../../../../services/admin/group";
import {Segment} from "../../../../types/segment";
import {useSegmentStore} from "../../../../stores/useSegmentStore";

interface AdminGroup {
    key: React.Key;
    id: number;
    label: string;
    segmentId: string;
    segmentLabel: string;
}

const AdminSectionGroups: React.FC = () => {
    const resetSegments = useSegmentStore((state) => state.reset);

    const [segments, setSegments] = useState<any>({});
    const [segment, setSegment] = useState<Segment>();

    const [data, setData] = useState<[]>([]);
    const [modalAdd, setModalAdd] = useState<boolean>(false);
    const [modalUpdate, setModalUpdate] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);

    const [group, setGroup] = useState<AdminGroup>();
    const [inpLabel, setInpLabel] = useState<string>();

    useEffect(() => {
        fetchSegments();
        fetchGroups();
    }, []);

    const fetchSegments = async () => {
        const res: ApiResponse = await AdminGetSegments();
        if (res && res.data) {
            const currSegments: any = {};
            res.data.forEach((s: any) => {
                currSegments[s.id] = s;
            });
            setSegments(currSegments);
        }
    };

    const fetchGroups = async () => {
        const res: any = await AdminGetGroups();
        if (res && res.data) {
            setData(res.data);
        }
    };

    const addGroup = async () => {
        if (!segment || !inpLabel) return;
        const res: ApiResponse = await AdminAddGroup(segment.id, {
            label: inpLabel,
        });
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchGroups();
            setModalAdd(false);
            setInpLabel("");
            resetSegments();
        }
    };

    const updateGroup = async () => {
        if (!segment || !group || !inpLabel) return;
        const res: ApiResponse = await AdminUpdateGroup(
            group.segmentId,
            group.id + "",
            {
                label: inpLabel,
                newSegmentId: segment.id,
            }
        );
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchGroups();
            setModalUpdate(false);
            setGroup(undefined);
            setInpLabel("");
            resetSegments();
        }
    };

    const deleteGroup = async () => {
        if (!group) return;
        const res: ApiResponse = await AdminDeleteGroup(
            group.segmentId,
            group.id + ""
        );
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchGroups();
            setModalDelete(false);
            resetSegments();
        }
    };

    const columns: ColumnsType<AdminGroup> = [
        {
            title: "Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "descend",
        },
        {
            title: "Building",
            dataIndex: "segmentLabel",
            sorter: (a, b) => a.segmentLabel.localeCompare(b.segmentLabel),
            filters: Object.values(segments).map((s: any) => {
                return {
                    text: s.label,
                    value: s.label,
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminGroup) =>
                record.segmentLabel.indexOf(value) === 0,
        },
        {
            title: "Room",
            dataIndex: "label",
            sorter: (a, b) => a.label.localeCompare(b.label),
            filters: data.map((f: any) => {
                return {
                    text: f.label,
                    value: f.label,
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminGroup) =>
                record.label.indexOf(value) === 0,
        },
        {
            title: "Actions",
            render: (group: AdminGroup) => {
                return (
                    <>
                        <BtnRound
                            label={() => <i className="fa fa-edit"/>}
                            className="warning"
                            selected={false}
                            onClick={() => {
                                setSegment(segments[group.segmentId]);
                                setGroup(group);
                                setInpLabel(group.label);
                                setModalUpdate(true);
                            }}
                        />
                        <BtnRound
                            label={() => <i className="fa fa-trash"/>}
                            className="danger"
                            selected={false}
                            onClick={() => {
                                setGroup(group);
                                setModalDelete(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <div className="groups-section">
            <div className="filter-bar">
                <Button onClick={() => setModalAdd(true)}>
                    <i className="fa fa-plus"></i> &nbsp; Add
                </Button>
            </div>
            <div className="table-wrapper">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        pageSize: 8,
                    }}
                />
            </div>

            <Modal
                title={`Add Room`}
                open={modalAdd}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalAdd(false);
                        setSegment(undefined);
                        setGroup(undefined);
                        setInpLabel(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={addGroup}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group">
                    <label>Building</label>
                    <Dropdown
                        menu={{
                            items: Object.values(segments).map((s: any) => {
                                return {
                                    key: s.id,
                                    label: s.label,
                                };
                            }),
                            selectable: true,
                            onSelect: (s: any) => setSegment(segments[s.key]),
                        }}
                        placement="bottomLeft"
                        className="d-block w-100"
                    >
                        <Button className={'text-left'}>{segment ? segment.label : "-- Select Building --"}</Button>
                    </Dropdown>
                </div>
                <div className="form-group">
                    <label>Label</label>
                    <Input
                        placeholder="Enter Label"
                        value={inpLabel}
                        onChange={(e: any) => setInpLabel(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Update Room`}
                open={modalUpdate}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalUpdate(false);
                        setSegment(undefined);
                        setGroup(undefined);
                        setInpLabel(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={updateGroup}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group">
                    <label>Building</label>
                    <Dropdown
                        menu={{
                            items: Object.values(segments).map((s: any) => {
                                return {
                                    key: s.id,
                                    label: s.label,
                                };
                            }),
                            selectable: true,
                            onSelect: (s: any) => setSegment(segments[s.key]),
                        }}
                        placement="bottomLeft"
                        className="d-block w-100"
                    >
                        <Button className={'text-left'}>{segment ? segment.label : "-- Select Building --"}</Button>
                    </Dropdown>
                </div>
                <div className="form-group">
                    <label>Label</label>
                    <Input
                        placeholder="Enter Label"
                        value={inpLabel}
                        onChange={(e: any) => setInpLabel(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Delete Room`}
                className="admin-panel"
                open={modalDelete}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalDelete(false);
                        setSegment(undefined);
                        setGroup(undefined);
                        setInpLabel(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={deleteGroup}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group">
                    Are you sure to delete <strong>"{group?.label}"</strong> room ?<br/>
                    <br/>
                    This action can't be undone. All your devices mapped with this room
                    will be unassigned.
                </div>
            </Modal>
        </div>
    );
};
export default AdminSectionGroups;
