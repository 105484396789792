import React, {useEffect} from "react";

import DeviceCardSwitch from "../../atoms/devices/switch";
import {useGroupStore} from "../../../stores/useGroupStore";
import {useSegmentStore} from "../../../stores/useSegmentStore";
import DeviceCardTemperature from "../../atoms/devices/temperature";
import {useDeviceStore} from "../../../stores/useDeviceStore";
import {useDeviceOptionStore} from "../../../stores/useDeviceOptionStore";
import {DeviceTrend} from "./trend";
import {DeviceNodeTypes} from "../../../types/device/node";
import DeviceCardDimmer from "../../atoms/devices/dimmer";
import {DeviceTemperature, DeviceTemperatureParams} from "../../../types/device/list/temperature";
import {Device, DeviceOptionMenuItem, DeviceTypes} from "../../../types/device";
import {DeviceSwitch, DeviceSwitchParams} from "../../../types/device/list/switch";
import {DeviceDimmer, DeviceDimmerParams} from "../../../types/device/list/dimmer";
import {JsonDeepCopy} from "../../../utils/common";
import {useWebSocketStore} from "../../../stores/useWebSocketStore";
import {WebSocketMessage} from "../../../types/websocket";
import {DeviceFlowMeter, DeviceFlowMeterParams} from "../../../types/device/list/flow-meter";
import DeviceCardFlowMeter from "../../atoms/devices/flow-meter";

const Devices: React.FC = () => {
    const segment = useSegmentStore((state) => state.selected);
    const group = useGroupStore((state) => state.selected);
    const setDevice = useDeviceStore((state) => state.setSelected);
    const devices = useDeviceStore((state) => state.data);
    const fetchDevices = useDeviceStore((state) => state.fetch);
    const fetchDeviceStatus = useDeviceStore((state) => state.fetchStatus);
    const deviceData = useDeviceStore((state) => state.deviceData);

    const setTrendModal = useDeviceOptionStore((state) => state.setTrendModal);

    const wsConnection = useWebSocketStore((state) => state.connection);

    const initializeDevice = async () => {
        if (segment && group) {
            await fetchDevices(segment.id, group.id);
            await fetchDeviceStatus(segment.id, group.id);
        }
    }

    useEffect(() => {
        initializeDevice();
    }, [group]);

    return (
        segment && group && (
            <div className="col-12 device-wrapper">
                <div className="devices">
                    {devices.map((d: Device) => {
                        if (d.type.code === DeviceTypes.IR_REMOTE_AC) {
                            const device = d as DeviceTemperature;
                            const wsMessage = JsonDeepCopy(deviceData[d.id] ?? {}) as WebSocketMessage;
                            if (wsMessage && wsMessage.payload && wsMessage.payload.data && wsMessage.payload.data.deviceId === d.id) {
                                device.isOnline = wsMessage.payload.data.isOnline;
                                device.params = wsMessage.payload.data.params as DeviceTemperatureParams;
                            }
                            return (
                                <DeviceCardTemperature
                                    {...device}
                                    onOptionItemSelected={(item: any) => {
                                        setDevice(device);
                                        if (item.itemKey === DeviceOptionMenuItem.TRENDS) {
                                            setTrendModal(true);
                                        }
                                    }}
                                    onClick={() => {
                                    }}
                                />
                            );
                        } else if (d.type.code === DeviceTypes.METER_WATER_FLOW) {
                            const device = d as DeviceFlowMeter;
                            const wsMessage = JsonDeepCopy(deviceData[d.id] ?? {}) as WebSocketMessage;
                            if (wsMessage && wsMessage.payload && wsMessage.payload.data && wsMessage.payload.data.deviceId === d.id) {
                                device.isOnline = wsMessage.payload.data.isOnline;
                                device.params = wsMessage.payload.data.params as DeviceFlowMeterParams;
                            }
                            return (
                                <DeviceCardFlowMeter
                                    {...device}
                                    onOptionItemSelected={(item: any) => {
                                        setDevice(device);
                                        if (item.itemKey === DeviceOptionMenuItem.TRENDS) {
                                            setTrendModal(true);
                                        }
                                    }}
                                    onClick={() => {
                                    }}
                                />
                            );
                        } else if (d.type.code === DeviceTypes.SWITCH_TOUCH_PANEL) {
                            const deviceCurr = d as DeviceSwitch;
                            return (
                                <>
                                    {d.nodes && d.nodes.map(deviceNode => {
                                        if (deviceNode.type === DeviceNodeTypes.DIMMER) {
                                            const device: DeviceDimmer = JsonDeepCopy(deviceCurr);
                                            device.nodeAttr = deviceNode.attr;
                                            device.label = deviceNode.label;
                                            device.nodes = undefined;
                                            const wsMessage = JsonDeepCopy(deviceData[d.id] ?? {}) as WebSocketMessage;
                                            if (wsMessage && wsMessage.payload && wsMessage.payload.data && wsMessage.payload.data.deviceId === d.id) {
                                                device.isOnline = wsMessage.payload.data.isOnline;
                                                device.params = wsMessage.payload.data.params as DeviceDimmerParams;
                                            }
                                            return (
                                                <DeviceCardDimmer
                                                    {...device}
                                                    onClick={async () => {
                                                        if (!device.isOnline) return;
                                                        if (!wsConnection || !wsMessage || !wsMessage.payload) return;
                                                        const payload = JsonDeepCopy(wsMessage.payload);
                                                        payload.data.params = {};
                                                        payload.data.params[device.nodeAttr] = 1;
                                                        payload.data.params[device.nodeAttr + '_freq'] = 50;
                                                        wsConnection.send('device', payload);
                                                    }}
                                                />
                                            );
                                        } else {
                                            const device: DeviceSwitch = JsonDeepCopy(deviceCurr);
                                            device.nodeAttr = deviceNode.attr;
                                            device.label = deviceNode.label;
                                            device.nodes = undefined;
                                            const wsMessage = JsonDeepCopy(deviceData[d.id] ?? {}) as WebSocketMessage;
                                            if (wsMessage && wsMessage.payload && wsMessage.payload.data && wsMessage.payload.data.deviceId === d.id) {
                                                device.isOnline = wsMessage.payload.data.isOnline;
                                                device.params = wsMessage.payload.data.params as DeviceSwitchParams;
                                            }
                                            return (
                                                <DeviceCardSwitch
                                                    {...device}
                                                    onClick={async () => {
                                                        if (!device.isOnline) return;
                                                        if (!wsConnection || !wsMessage || !wsMessage.payload) return;
                                                        const payload = JsonDeepCopy(wsMessage.payload);
                                                        payload.data.params = {};
                                                        payload.data.params[device.nodeAttr] = wsMessage.payload.data.params[device.nodeAttr] === 1 ? 0 : 1;
                                                        console.log(payload, device);
                                                        wsConnection.send('device', payload);
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </>
                            );
                        }
                        return <></>
                    })}
                </div>
                <DeviceTrend/>
            </div>
        )
    );
};

export default Devices;
