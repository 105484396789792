import {create} from "zustand";

import {Group} from "../types/group";
import {JsonDeepCopy} from "../utils/common";
import {GetGroups} from "../services/group";

interface GroupState {
    selected: Group | null;
    setSelected: (selected: Group | null) => void;
    data: Group[];
    fetch: (segmentId: string) => void;
    reset: () => void;
}

export const useGroupStore = create<GroupState>((set, get) => ({
    selected: null,
    setSelected: (selected: Group | null) =>
        set({selected: JsonDeepCopy(selected)}),
    data: [],
    fetch: async (segmentId: string) => {
        const res: any = await GetGroups(segmentId);
        if (res.data && res.data.length > 0) {
            set({data: res.data});
            if (get().selected === null) {
                get().setSelected(res.data[0]);
            }
        }
    },
    reset: () => {
        set({data: [], selected: null});
    },
}));
