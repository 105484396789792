import {Button, Dropdown, Input, Modal, Switch, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import {useEffect, useState} from "react";
import BtnRound from "../../../atoms/buttons/round";
import {AdminGetSegments} from "../../../../services/admin/segment";
import {ApiResponse, ApiResponseType} from "../../../../types/api";
import {AdminGetGroups} from "../../../../services/admin/group";
import {Segment} from "../../../../types/segment";
import {useSegmentStore} from "../../../../stores/useSegmentStore";
import {AdminGetDevices, AdminUpdateDevice, AdminUpdateDeviceConfigs,} from "../../../../services/admin/device";
import {Group} from "../../../../types/group";
import {Device} from "../../../../types/device";
import {ModuleConfigs} from "../../../../configs/module";
import {DeviceConfigUpdateReq} from "../../../../types/web/requests/device";

const {TextArea} = Input;

interface AdminDevice extends Device {
    key: React.Key;
    segmentId: string;
    segmentLabel: string;
    groupId: string;
    groupLabel: string;
    udfNum1: number;
}

const AdminSectionDevices: React.FC = () => {
    const resetSegments = useSegmentStore((state) => state.reset);

    const [segmentsLoaded, setSegmentsLoaded] = useState<boolean>(false);
    const [segments, setSegments] = useState<any>({});
    const [segment, setSegment] = useState<Segment>();

    const [groupsLoaded, setGroupsLoaded] = useState<boolean>(false);
    const [groups, setGroups] = useState<any>({});
    const [group, setGroup] = useState<Group>();

    const [data, setData] = useState<AdminDevice[]>([]);
    const [modalUpdate, setModalUpdate] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<boolean>(false);
    const [device, setDevice] = useState<AdminDevice>();

    const [inpLabel, setInpLabel] = useState<string>();
    const [inpComment, setInpComment] = useState<string>();
    const [inpUdfNum1, setInpUdfNum1] = useState<number>();
    const [inpConfigDataLog, setInpConfigDataLog] = useState<"Y" | "N">();
    const [inpConfigIsEnabled, setInpConfigIsEnabled] = useState<"Y" | "N">();

    useEffect(() => {
        initialise();
    }, []);

    useEffect(() => {
        if (segmentsLoaded && groupsLoaded) {
            fetchDevices();
        }
    }, [segments, groups]);

    const initialise = async () => {
        await Promise.all([fetchSegments(), fetchGroups()]);
    };

    const fetchSegments = async () => {
        const res: ApiResponse = await AdminGetSegments();
        if (res && res.data) {
            const currSegments: any = {};
            res.data.forEach((s: any) => {
                currSegments[s.id] = s;
            });
            setSegments(currSegments);
            setSegmentsLoaded(true);
        }
    };

    const fetchGroups = async () => {
        const res: ApiResponse = await AdminGetGroups();
        if (res && res.data) {
            const currGroups: any = {};
            res.data.forEach((s: any) => {
                currGroups[s.id] = s;
            });
            setGroups(currGroups);
            setGroupsLoaded(true);
        }
    };

    const fetchDevices = async () => {
        const res: any = await AdminGetDevices();
        if (res && res.data) {
            setData(
                res.data.map((d: AdminDevice) => {
                    if (segments[d.segmentId])
                        d.segmentLabel = segments[d.segmentId].label;
                    if (groups[d.groupId]) d.groupLabel = groups[d.groupId].label;
                    return d;
                })
            );
        }
    };

    const updateDevice = async () => {
        if (!segment || !group || !device || !inpLabel) return;
        const res: ApiResponse = await AdminUpdateDevice(device.id + "", {
            label: inpLabel,
            newSegmentId: segment.id,
            newGroupId: group.id,
        });
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchDevices();
            setModalUpdate(false);
            setDevice(undefined);
            setInpLabel("");
            resetSegments();
        }
    };

    const updateConfigs = async () => {
        if (!device || !inpConfigDataLog || !inpConfigIsEnabled) return;
        const configReq: DeviceConfigUpdateReq = {
            configs: {
                dataLog: inpConfigDataLog,
                isEnabled: inpConfigIsEnabled,
            },
            comment: inpComment ?? '',
        };
        if (inpUdfNum1 != undefined) configReq.udfNum1 = inpUdfNum1;
        const res: ApiResponse = await AdminUpdateDeviceConfigs(device.id + "", configReq);
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchDevices();
            setModalConfig(false);
            setDevice(undefined);
            setInpConfigDataLog(undefined);
            setInpConfigIsEnabled(undefined);
            setInpComment(undefined);
            resetSegments();
        }
    };

    const columns: ColumnsType<AdminDevice> = [
        {
            title: "Id",
            dataIndex: "id",
            sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
            defaultSortOrder: "descend",
        },
        {
            title: "Building",
            dataIndex: "segmentLabel",
            sorter: (a, b) => {
                if (a && b && a.segmentLabel && b.segmentLabel) return a.segmentLabel.localeCompare(b.segmentLabel);
                return -1;
            },
            filters: Object.values(segments).map((s: any) => {
                return {
                    text: s.label,
                    value: s.label,
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminDevice) => {
                if (record && record.groupLabel && record.segmentLabel.indexOf(value) === 0) return true;
                return false;
            },
        },
        {
            title: "Room",
            dataIndex: "groupLabel",
            sorter: (a, b) => {
                if (a && b && a.groupLabel && b.groupLabel) return a.groupLabel.localeCompare(b.groupLabel);
                return -1;
            },
            filters: Object.values(groups).map((s: any) => {
                return {
                    text: s.label,
                    value: s.label,
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminDevice) => {
                if (record && record.groupLabel && record.groupLabel.indexOf(value) === 0) return true;
                return false;
            },
        },
        {
            title: "Device",
            dataIndex: "label", //['configs', 'isEnabled']
            sorter: (a, b) => a.label.localeCompare(b.label),
            filters: data.map((device: AdminDevice) => {
                return {
                    text: device.label,
                    value: device.label,
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminDevice) => {
                if (record && record.label && record.label.indexOf(value) === 0) return true;
                return false;
            },
        },
        {
            title: "Status",
            render: (value: any, record: AdminDevice) => {
                return (
                    <>
                        {record.configs.isEnabled === "Y" && (
                            <Tag color="success">Enabled</Tag>
                        )}
                        {record.configs.isEnabled === "N" && (
                            <Tag color="error">Disabled</Tag>
                        )}
                        {record.comment && (
                            <Tag color="default" title={record.comment} style={{cursor: 'pointer'}}>
                                <i className="fa fa-comment"/>
                            </Tag>
                        )}
                    </>
                );
            },
            sorter: (a, b) => a.configs.isEnabled.localeCompare(b.configs.isEnabled),
            filters: ["Y", "N"].map((f: string) => {
                return {
                    text: f === "Y" ? "Enabled" : "Disabled",
                    value: f,
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminDevice) =>
                record.configs.isEnabled.indexOf(value) === 0,
        },
        {
            title: "Actions",
            render: (device: AdminDevice) => {
                return (
                    <>
                        <BtnRound
                            label={() => <i className="fa fa-edit"/>}
                            className="warning"
                            selected={false}
                            onClick={() => {
                                setSegment(segments[device.segmentId]);
                                setGroup(groups[device.groupId]);
                                setDevice(device);
                                setInpLabel(device.label);
                                setModalUpdate(true);
                            }}
                        />
                        <BtnRound
                            label={() => <i className="fa fa-cog"/>}
                            className="info"
                            selected={false}
                            onClick={() => {
                                setSegment(segments[device.segmentId]);
                                setGroup(groups[device.groupId]);
                                setDevice(device);
                                setInpConfigDataLog(device.configs.dataLog);
                                setInpConfigIsEnabled(device.configs.isEnabled);
                                setInpComment(device.comment);
                                setInpUdfNum1(device.udfNum1);
                                setModalConfig(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <div className="groups-section">
            <div className="table-wrapper">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        pageSize: 8,
                    }}
                />
            </div>

            <Modal
                title={`Update Device`}
                open={modalUpdate}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalUpdate(false);
                        setSegment(undefined);
                        setGroup(undefined);
                        setDevice(undefined);
                        setInpLabel(undefined);
                        setInpConfigDataLog(undefined);
                        setInpConfigIsEnabled(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={updateDevice}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group">
                    <label>Building</label>
                    <Dropdown
                        menu={{
                            items: Object.values(segments).map((s: any) => {
                                return {
                                    key: s.id,
                                    label: s.label,
                                };
                            }),
                            selectable: true,
                            selectedKeys: segment ? [segment.id] : [],
                            onSelect: (s: any) => {
                                setSegment(segments[s.key]);
                                setGroup(undefined);
                            },
                        }}
                        placement="bottomLeft"
                        className="d-block w-100"
                    >
                        <Button className={'text-left'}>{segment ? segment.label : "-- Select Building --"}</Button>
                    </Dropdown>
                </div>
                <div className="form-group">
                    <label>Room</label>
                    <Dropdown
                        menu={{
                            items: Object.values(groups)
                                .filter((g: any) => {
                                    return segment && segment.id + "" === g.segmentId + "";
                                })
                                .map((g: any) => {
                                    return {
                                        key: g.id,
                                        label: g.label,
                                    };
                                }),
                            selectable: true,
                            selectedKeys: group ? [group.id] : [],
                            onSelect: (s: any) => setGroup(groups[s.key]),
                        }}
                        placement="bottomLeft"
                        className="d-block w-100"
                    >
                        <Button className={'text-left'}>{group ? group.label : "-- Select Room --"}</Button>
                    </Dropdown>
                </div>
                <div className="form-group">
                    <label>Label</label>
                    <Input
                        placeholder="Enter Label"
                        value={inpLabel}
                        onChange={(e: any) => setInpLabel(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Device Configuration`}
                open={modalConfig}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalConfig(false);
                        setSegment(undefined);
                        setGroup(undefined);
                        setDevice(undefined);
                        setInpLabel(undefined);
                        setInpComment(undefined);
                        setInpUdfNum1(undefined);
                        setInpConfigDataLog(undefined);
                        setInpConfigIsEnabled(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={updateConfigs}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                {ModuleConfigs.ENABLE_DEVICE_LOGGING && (
                    <div className="row mt-4">
                        <div className="col-8">Log Device Data</div>
                        <div className="col-4 text-right">
                            <Switch
                                size="small"
                                checked={inpConfigDataLog === "Y"}
                                onChange={(checked: boolean) =>
                                    setInpConfigDataLog(checked ? "Y" : "N")
                                }
                            />
                        </div>
                    </div>
                )}
                <div className="row mt-4">
                    <div className="col-8">Enable Device</div>
                    <div className="col-4 text-right">
                        <Switch
                            size="small"
                            checked={inpConfigIsEnabled === "Y"}
                            onChange={(checked: boolean) =>
                                setInpConfigIsEnabled(checked ? "Y" : "N")
                            }
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-8">UDF Num 1 (AC Tonnage)</div>
                    <div className="col-4 text-right">
                        <Input
                            type="number"
                            placeholder="Enter UDF Num 1"
                            value={inpUdfNum1}
                            onChange={(e: any) => setInpUdfNum1(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">Comment</div>
                    <div className="col-12 text-right">
                        <TextArea
                            placeholder={'Enter the comment'}
                            value={inpComment}
                            onChange={(e: any) => setInpComment(e.target.value)}
                            rows={4}
                        />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12">&nbsp;</div>
                </div>
            </Modal>
        </div>
    );
};
export default AdminSectionDevices;
