import React, {useState} from "react";
import bulbOff from "../../../../assets/images/devices/bulb-off.png";
import bulbOn from "../../../../assets/images/devices/bulb-on.png";
import deviceOffline from "../../../../assets/images/devices/offline.png";
import {Tag, Tooltip} from "antd";
import {DeviceSwitch} from "../../../types/device/list/switch";

const DeviceCardSwitch: React.FC<DeviceSwitch & { onClick: () => void }> = (
    props
) => {

    const status: 'on' | 'off' = ((props.params as any ?? {})[props.nodeAttr ?? ''] === 0) ? 'on' : 'off';

    const getBadgeStatus = () => {
        return props.isOnline ? status : "offline";
    };

    const getBadgeSrc = () => {
        if (!props.isOnline) return deviceOffline;
        return status === 'on' ? bulbOn : bulbOff;
    };

    const [tooltip, setTooltip] = useState<boolean>(false);

    return (
        <div
            className="col-sm-12 col-md-4 col-lg-4 col-xl-3"
            style={{display: "inline-block"}}
        >
            <div
                className={`device ${
                    !props.isOnline || props.configs.isEnabled === "N" ? "offline" : ""
                }`}
                onClick={() => {
                    if (props.configs.isEnabled === "Y") {
                        props.onClick();
                    } else {
                        setTooltip(!tooltip);
                        setTimeout(() => {
                            setTooltip(false);
                        }, 2000);
                    }
                }}
            >
                {props.configs.isEnabled === "N" && (
                    <div className="disabled">
                        <Tag color="error">Disabled</Tag>
                    </div>
                )}
                {props.configs.isEnabled === "N" && (
                    <Tooltip
                        title="Device disabled. Contact Admin"
                        open={tooltip}
                        placement="topLeft"
                    ></Tooltip>
                )}
                <div className={`device-badge device-type-switch ${getBadgeStatus()}`}>
                    <img src={getBadgeSrc()} alt={props.label}/>
                </div>
                <div className="label">{props.label}</div>
            </div>
        </div>
    );
};

export default DeviceCardSwitch;
