import {Button, Dropdown, Input, Modal, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import BtnRound from "../../../atoms/buttons/round";
import {ApiResponse, ApiResponseType} from "../../../../types/api";
import {
    AdminAddUser,
    AdminAddUserSegment,
    AdminDeleteUser,
    AdminDeleteUserSegment,
    AdminGetUsers,
    AdminUpdateUser
} from "../../../../services/admin/user";
import {useUserStore} from "../../../../stores/useUserStore";
import {AdminGetSegments} from "../../../../services/admin/segment";
import {JsonDeepCopy} from "../../../../utils/common";
import {Segment} from "../../../../types/segment";

interface AdminUser {
    key: React.Key;
    id: number;
    label: string;
    username: string;
    type: 'admin' | 'user';
    segments: number[];
}

const AdminSectionUsers: React.FC = () => {

    const loggedInUser = useUserStore(state => state.user);

    const [segments, setSegments] = useState<any>({});
    const [segment, setSegment] = useState<Segment>();

    const [data, setData] = useState<[]>([]);
    const [modalAdd, setModalAdd] = useState<boolean>(false);
    const [modalUpdate, setModalUpdate] = useState<boolean>(false);
    const [modalPassword, setModalPassword] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);
    const [modalAssignSegment, setModalAssignSegment] = useState<boolean>(false);

    const [user, setUser] = useState<AdminUser>();
    const [inpLabel, setInpLabel] = useState<string>();
    const [inpUsername, setInpUsername] = useState<string>();
    const [inpPassword, setInpPassword] = useState<string>();
    const [inpConfPassword, setInpConfPassword] = useState<string>();
    const [inpType, setInpType] = useState<'admin' | 'user'>();

    useEffect(() => {
        fetchSegments();
        fetchUsers();
    }, []);

    const fetchSegments = async () => {
        const res: ApiResponse = await AdminGetSegments();
        if (res && res.data) {
            const currSegments: any = {};
            res.data.forEach((s: any) => {
                currSegments[s.id] = s;
            });
            setSegments(currSegments);
        }
    };

    const fetchUsers = async (callback?: Function) => {
        const res: any = await AdminGetUsers();
        if (res && res.data) {
            setData(res.data);
            if (callback) callback(res.data);
        }
    };

    const resetStates = () => {
        setSegment(undefined);
        setUser(undefined);
        setInpType(undefined);
        setInpLabel(undefined);
        setInpUsername(undefined);
        setInpPassword(undefined);
        setInpConfPassword(undefined);
    }

    const addUser = async () => {
        if (!inpLabel || !inpUsername || !inpPassword || !inpType) return;
        const res: ApiResponse = await AdminAddUser(
            {label: inpLabel, username: inpUsername, password: inpPassword, type: inpType});
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchUsers();
            setModalAdd(false);
            resetStates();
        }
    };

    const updateUser = async () => {
        if (!user || !inpLabel || !inpUsername || !inpType) return;
        const res: ApiResponse = await AdminUpdateUser(user.id + "",
            {label: inpLabel, username: inpUsername, type: inpType});
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchUsers();
            setModalUpdate(false);
            resetStates();
        }
    };

    const updatePassword = async () => {
        if (!user || !inpLabel || !inpUsername || !inpPassword || !inpType) return;
        if (inpPassword !== inpConfPassword) return;
        const res: ApiResponse = await AdminUpdateUser(user.id + "",
            {label: inpLabel, username: inpUsername, password: inpPassword, type: inpType});
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchUsers();
            setModalPassword(false);
            resetStates();
        }
    };

    const deleteUser = async () => {
        if (!user) return;
        const res: ApiResponse = await AdminDeleteUser(user?.id + "");
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchUsers();
            setModalDelete(false);
            resetStates();
        }
    };

    const addUserSegment = async () => {
        if (!user || !segment) return;
        const res: ApiResponse = await AdminAddUserSegment(user.id + '', segment.id + '');
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchUsers((data: AdminUser[]) => {
                const userObj: AdminUser = JsonDeepCopy(user);
                resetStates();
                data.forEach(d => {
                    if (d.id === userObj.id) {
                        setUser(d);
                    }
                })
            });
        }
    };

    const deleteUserSegment = async (userId: string, segmentId: string) => {
        if (!user) return;
        const res: ApiResponse = await AdminDeleteUserSegment(userId, segmentId);
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchUsers((data: AdminUser[]) => {
                const userObj: AdminUser = JsonDeepCopy(user);
                resetStates();
                data.forEach(d => {
                    if (d.id === userObj.id) {
                        setUser(d);
                    }
                })
            });
        }
    };

    const columns: ColumnsType<AdminUser> = [
        {
            title: "Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "descend",
        },
        {
            title: "Full Name",
            dataIndex: "label",
            sorter: (a, b) => a.label.localeCompare(b.label),
        },
        {
            title: "Username",
            dataIndex: "username",
            sorter: (a, b) => a.username.localeCompare(b.username),
        },
        {
            title: "Type",
            render: (value: any, record: AdminUser) => {
                return (
                    <>
                        {record.type === 'admin' && (
                            <Tag color="blue">Admin</Tag>
                        )}
                        {record.type === 'user' && (
                            <Tag color="gold">User</Tag>
                        )}
                    </>
                );
            },
            sorter: (a, b) => a.type.localeCompare(b.type),
            filters: ["Admin", "User"].map((f: string) => {
                return {
                    text: f,
                    value: f.toLocaleLowerCase(),
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminUser) =>
                record.type.indexOf(value) === 0,
        },
        {
            title: "Assigned Building",
            render: (value: any, record: AdminUser) => {
                return record.type === 'user' && (
                    <>
                        {(record.segments.length < 1) && (
                            <Tag color="error" className="segments-assigned-btn" onClick={() => {
                                setUser(record);
                                setModalAssignSegment(true);
                            }}>None</Tag>
                        )}
                        {record.segments.length > 0 && (
                            <Tag color="default" className="segments-assigned-btn" onClick={() => {
                                setUser(record);
                                setModalAssignSegment(true);
                            }}>
                                <strong>{record.segments.length}</strong> Buildings
                            </Tag>
                        )}
                    </>
                );
            },
            filters: [{label: '-- None --', id: 0}, ...Object.values(segments)].map((s: any) => {
                return {
                    text: s.label,
                    value: s.id,
                };
            }),
            filterSearch: true,
            onFilter: (value: any, record: AdminUser) => {
                if (record.type !== 'user') return false;
                if (value === 0 && record.segments.length === 0) return true;
                return record.segments.indexOf(value) === 0
            },
        },
        {
            title: "Actions",
            render: (user: any) => {
                return (
                    <>
                        <BtnRound
                            label={() => <i className="fa fa-edit"/>}
                            className="warning"
                            selected={false}
                            onClick={() => {
                                setUser(user);
                                setInpLabel(user.label);
                                setInpType(user.type);
                                setInpUsername(user.username);
                                setModalUpdate(true);
                            }}
                            title="Update User Details"
                        />
                        <BtnRound
                            label={() => <i className="fa fa-lock"/>}
                            className="info"
                            selected={false}
                            onClick={() => {
                                setUser(user);
                                setInpLabel(user.label);
                                setInpType(user.type);
                                setInpUsername(user.username);
                                setModalPassword(true);
                            }}
                            title="Update Password"
                        />
                        {user.id !== loggedInUser?.id && (
                            <BtnRound
                                label={() => <i className="fa fa-trash"/>}
                                className="danger"
                                selected={false}
                                onClick={() => {
                                    setUser(user);
                                    setModalDelete(true);
                                }}
                                title="Delete User"
                            />
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div className="users-section">
            <div className="filter-bar">
                <Button onClick={() => setModalAdd(true)}>
                    <i className="fa fa-plus"></i> &nbsp; Add
                </Button>
            </div>
            <div className="table-wrapper">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        pageSize: 8,
                    }}
                />
            </div>

            <Modal
                title={`Add New User`}
                open={modalAdd}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalAdd(false);
                        resetStates();
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={addUser}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group mt-3">
                    <label>User Type</label>
                    <Dropdown
                        menu={{
                            items: ['admin', 'user'].map((s: any) => {
                                return {
                                    key: s,
                                    label: s,
                                };
                            }),
                            selectable: true,
                            selectedKeys: inpType ? [inpType] : [],
                            onSelect: (s: any) => setInpType(s.key),
                        }}
                        placement="bottomLeft"
                        className="d-block w-100"
                    >
                        <Button className={'text-left'}>
                            {
                                inpType !== undefined ? inpType : (user ? user.type : "-- Select Type --")
                            }
                        </Button>
                    </Dropdown>
                </div>
                <div className="form-group">
                    <label>Full Name</label>
                    <Input
                        placeholder="Enter Full Name"
                        value={inpLabel}
                        onChange={(e: any) => setInpLabel(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4">
                    <label>Login Username</label>
                    <Input
                        placeholder="Enter Username"
                        value={inpUsername}
                        onChange={(e: any) => setInpUsername(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4">
                    <label>Password</label>
                    <Input
                        type="password"
                        placeholder="Enter Password"
                        value={inpPassword}
                        onChange={(e: any) => setInpPassword(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Update User Details`}
                open={modalUpdate}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalUpdate(false);
                        resetStates();
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={updateUser}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group mt-3">
                    <label>User Type</label>
                    <Dropdown
                        disabled={user?.id === loggedInUser?.id}
                        menu={{
                            items: ['admin', 'user'].map((s: any) => {
                                return {
                                    key: s,
                                    label: s,
                                };
                            }),
                            selectable: true,
                            selectedKeys: inpType ? [inpType] : [],
                            onSelect: (s: any) => setInpType(s.key),
                        }}
                        placement="bottomLeft"
                        className="d-block w-100"
                    >
                        <Button className={'text-left'}>
                            {
                                inpType !== undefined ? inpType : (user ? user.type : "-- Select Type --")
                            }
                        </Button>
                    </Dropdown>
                </div>
                <div className="form-group">
                    <label>Full Name</label>
                    <Input
                        placeholder="Enter Full Name"
                        value={inpLabel}
                        onChange={(e: any) => setInpLabel(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4">
                    <label>Login Username</label>
                    <Input
                        placeholder="Enter Username"
                        value={inpUsername}
                        onChange={(e: any) => setInpUsername(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Update Password`}
                open={modalPassword}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalPassword(false);
                        resetStates();
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={updatePassword}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group mt-3">
                    <label>New Password</label>
                    <Input
                        type="password"
                        placeholder="New Password"
                        value={inpPassword}
                        onChange={(e: any) => setInpPassword(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4">
                    <label>Confirm Password</label>
                    <Input
                        type="password"
                        placeholder="Confirm Password"
                        value={inpConfPassword}
                        onChange={(e: any) => setInpConfPassword(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Delete User`}
                className="admin-panel"
                open={modalDelete}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalDelete(false);
                        resetStates();
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={deleteUser}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group">
                    Are you sure to delete <strong>"{user?.label} ({user?.username})"</strong> ?
                    <br/>
                    <br/>
                    This action can't be undone.
                </div>
            </Modal>

            <Modal
                title={`Assign Buildings (${user?.username})`}
                className="admin-panel"
                open={modalAssignSegment}
                closable={true}
                width={360}
                footer={[]}
                onCancel={() => {
                    setModalAssignSegment(false);
                    resetStates();
                }}
            >
                <div className="form-group mt-3">
                    <label>New Building</label><br/>
                    <Dropdown
                        menu={{
                            items: Object.values(segments)
                                .filter((s: any) => !user?.segments.includes(parseInt(s.id)))
                                .map((s: any) => {
                                    return {
                                        key: s.id,
                                        label: s.label,
                                    };
                                }),
                            selectable: true,
                            onSelect: (s: any) => setSegment(segments[s.key]),
                        }}
                        placement="bottomLeft"
                        className="segments-assigned-add"
                    >
                        <Button className={'text-left'}>{segment ? segment.label : "-- Select Building --"}</Button>
                    </Dropdown>&nbsp;
                    <Button disabled={segment === undefined} className="text-center" type="primary"
                            onClick={addUserSegment}>
                        <i className="fa fa-plus"/>
                    </Button>
                </div>
                <div className="form-group mt-2" style={{padding: '10px', border: '1px dashed #ccc'}}>
                    {user?.segments.map((segmentId: number) => {
                        return (
                            <BtnRound
                                className="segments-assigned-btn"
                                label={() => {
                                    return (
                                        <>
                                            <i className="fa fa-times"
                                               onClick={() => deleteUserSegment(user?.id + '', segmentId + '')}/>&nbsp;&nbsp;
                                            <label>{segments[segmentId].label}</label>
                                        </>
                                    );
                                }}
                                selected={false}
                                onClick={() => {
                                }}
                            />
                        );
                    })}
                    {user && user.segments.length < 1 && (
                        <div className="text-center"><i className="fa fa-frown-o"/> None</div>
                    )}
                </div>
            </Modal>
        </div>
    );
};
export default AdminSectionUsers;
