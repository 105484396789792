import {create} from "zustand";
import {GetDevices, GetDevicesStatus} from "../services/device";
import {Device} from "../types/device";
import {WebSocketMessage} from "../types/websocket";
import {JsonDeepCopy} from "../utils/common";

interface DeviceState {
    selected: Device | null;
    setSelected: (selected: Device | null) => void;
    data: Device[];
    fetch: (segmentId: string, groupId: string) => void;
    fetchStatus: (segmentId: string, groupId: string) => void;
    deviceData: { [deviceId: string]: WebSocketMessage },
    setDeviceData: (wsMessage: WebSocketMessage) => void;
    reset: () => void;
}

export const useDeviceStore = create<DeviceState>((set, get) => ({
    selected: null,
    setSelected: (selected: Device | null) => set({selected}),
    data: [],
    fetch: async (segmentId: string, groupId: string) => {
        const res: any = await GetDevices(segmentId, groupId);
        if (res.data) {
            set({data: res.data});
        }
    },
    fetchStatus: async (segmentId: string, groupId: string) => {
        const res: any = await GetDevicesStatus(segmentId, groupId);
        if (res.data) {
            set({data: res.data});
        }
    },
    deviceData: {},
    setDeviceData: (wsMessage: WebSocketMessage) => {
        const newDeviceData = JsonDeepCopy(get().deviceData);
        if (wsMessage.payload) {
            newDeviceData[wsMessage.payload.data.deviceId] = wsMessage;
            set({deviceData: newDeviceData});
        }
    },
    reset: () => {
        set({data: []});
    },
}));
