export enum ApiResponseType {
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
}

export type ApiResponseMeta = {
    status: ApiResponseType;
    message: string;
};

export type ApiResponse = {
    meta: ApiResponseMeta;
    data?: any;
};
