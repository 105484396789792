import React, {useState} from "react";
import {Button, Modal} from "antd";
import {useUserStore} from "../../stores/useUserStore";
import {AppConfigs} from "../../configs/app";

const LogoutSelector = () => {

    const [modal, setModal] = useState<boolean>(false);
    const processLogout = useUserStore((state) => state.logout);

    return (
        <>
            <div
                className="item"
                title="Logout"
                onClick={() => setModal(true)}
            >
                <i className="fa fa-sign-out"/>
            </div>
            <Modal
                title={`Logging out ...`}
                className="admin-panel"
                open={modal}
                closable={false}
                width={380}
                footer={[
                    <Button onClick={() => setModal(false)}>
                        <i className="fa fa-times"/> &nbsp; No
                    </Button>,
                    <Button type="primary" onClick={processLogout}>
                        Yes &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group">
                    Are you sure to logout out of the <strong>{AppConfigs.ORG_NAME}</strong> ?
                </div>
            </Modal>
        </>
    );
}
export default LogoutSelector;
