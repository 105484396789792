import axios, {AxiosError, AxiosResponse} from "axios";
import ApiConfigs from "../configs/api";
import {ApiResponse} from "../types/api";

export const GetReportData = async (params: {
    reportType: 'energy',
    segmentId: string,
    dateFrom: string,
    dateUpto: string
}): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .get(
            `${ApiConfigs.CORE_SERVER_URL}/report/${params.reportType}?dateFrom=${params.dateFrom}&dateUpto=${params.dateUpto}&segmentId=${params.segmentId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
export const GetReportDownload = (params: { reportType: 'energy', dateFrom: string, dateUpto: string }) => {
    const token = localStorage.getItem("token");
    return `${ApiConfigs.CORE_SERVER_URL}/report/${params.reportType}/download?dateFrom=${params.dateFrom}&dateUpto=${params.dateUpto}&token=${token}`
};
