import axios, {AxiosError, AxiosResponse} from "axios";
import ApiConfigs from "../../configs/api";
import {ApiResponse} from "../../types/api";

export const AdminGetUsers = async (): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .get(ApiConfigs.CORE_SERVER_URL + "/user", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminAddUser = async (reqBody: {
    label: string;
    username: string;
    password: string;
    type: string;
}): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .post(ApiConfigs.CORE_SERVER_URL + "/user", reqBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminUpdateUser = async (
    id: string,
    reqBody: {
        label: string;
        username: string;
        password?: string;
        type: string;
    }
): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .put(ApiConfigs.CORE_SERVER_URL + "/user/" + id, reqBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminDeleteUser = async (id: string): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .delete(ApiConfigs.CORE_SERVER_URL + "/user/" + id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminAddUserSegment = async (id: string, segmentId: string): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .post(ApiConfigs.CORE_SERVER_URL + "/user/" + id + "/segment/" + segmentId, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminDeleteUserSegment = async (id: string, segmentId: string): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .delete(ApiConfigs.CORE_SERVER_URL + "/user/" + id + "/segment/" + segmentId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
