import {DeviceNode} from "./node";

export enum DeviceTypes {
    METER_ELECTRIC = 'METER_ELECTRIC',
    METER_WATER_FLOW = 'METER_WATER_FLOW',
    IR_REMOTE_AC = 'IR_REMOTE_AC',
    SWITCH_TOUCH_PANEL = 'SWITCH_TOUCH_PANEL',
}

export enum DeviceOptionMenuItem {
    TRENDS = 'TRENDS',
}

export interface Device {
    id: string;
    label: string;
    type: {
        label: string;
        code: DeviceTypes;
    };
    configs: {
        dataLog: "Y" | "N";
        isEnabled: "Y" | "N";
    };
    isOnline: boolean;
    onOptionItemSelected: (item: { deviceId: string, itemKey: DeviceOptionMenuItem }) => void;
    comment: string;
    nodes?: DeviceNode[];
}
