import SegmentSelector from "../../molecules/segmentSelector";
import SegmentContent from "./content";

const Segments: React.FC = () => {
    return (
        <>
            <SegmentSelector/>
            {/*<SegmentSidebar />*/}
            <SegmentContent/>
        </>
    );
};
export default Segments;
