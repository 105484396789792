import {Button, Input, Modal, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {useEffect, useState} from "react";
import BtnRound from "../../../atoms/buttons/round";
import {
    AdminAddSegment,
    AdminDeleteSegment,
    AdminGetSegments,
    AdminUpdateSegment,
} from "../../../../services/admin/segment";
import {ApiResponse, ApiResponseType} from "../../../../types/api";
import {useSegmentStore} from "../../../../stores/useSegmentStore";

interface AdminSegment {
    key: React.Key;
    id: number;
    label: string;
}

const AdminSectionSegments: React.FC = () => {
    const resetSegments = useSegmentStore((state) => state.reset);

    const [data, setData] = useState<[]>([]);
    const [modalAdd, setModalAdd] = useState<boolean>(false);
    const [modalUpdate, setModalUpdate] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);

    const [segment, setSegment] = useState<AdminSegment>();
    const [inpLabel, setInpLabel] = useState<string>();

    useEffect(() => {
        fetchSegments();
    }, []);

    const fetchSegments = async () => {
        const res: any = await AdminGetSegments();
        if (res && res.data) {
            setData(res.data);
        }
    };

    const addSegment = async () => {
        if (!inpLabel) return;
        const res: ApiResponse = await AdminAddSegment({label: inpLabel});
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchSegments();
            setModalAdd(false);
            setInpLabel("");
            resetSegments();
        }
    };

    const updateSegment = async () => {
        if (!inpLabel || !segment) return;
        const res: ApiResponse = await AdminUpdateSegment(segment.id + "", {
            label: inpLabel,
        });
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchSegments();
            setModalUpdate(false);
            setSegment(undefined);
            setInpLabel("");
            resetSegments();
        }
    };

    const deleteSegment = async () => {
        if (!segment) return;
        const res: ApiResponse = await AdminDeleteSegment(segment?.id + "");
        if (res && res.meta && res.meta.status === ApiResponseType.SUCCESS) {
            fetchSegments();
            setModalDelete(false);
            resetSegments();
        }
    };

    const columns: ColumnsType<AdminSegment> = [
        {
            title: "Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "descend",
        },
        {
            title: "Building",
            dataIndex: "label",
            sorter: (a, b) => a.label.localeCompare(b.label),
        },
        {
            title: "Actions",
            render: (segment: AdminSegment) => {
                return (
                    <>
                        <BtnRound
                            label={() => <i className="fa fa-edit"/>}
                            className="warning"
                            selected={false}
                            onClick={() => {
                                setSegment(segment);
                                setInpLabel(segment.label);
                                setModalUpdate(true);
                            }}
                        />
                        <BtnRound
                            label={() => <i className="fa fa-trash"/>}
                            className="danger"
                            selected={false}
                            onClick={() => {
                                setSegment(segment);
                                setModalDelete(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <div className="segments-section">
            <div className="filter-bar">
                <Button onClick={() => setModalAdd(true)}>
                    <i className="fa fa-plus"></i> &nbsp; Add
                </Button>
            </div>
            <div className="table-wrapper">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        pageSize: 8,
                    }}
                />
            </div>

            <Modal
                title={`Add Building`}
                open={modalAdd}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalAdd(false);
                        setSegment(undefined);
                        setInpLabel(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={addSegment}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group mt-3 mb-4">
                    <label>Label</label>
                    <Input
                        placeholder="Enter Label"
                        value={inpLabel}
                        onChange={(e: any) => setInpLabel(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Update Building`}
                open={modalUpdate}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalUpdate(false);
                        setSegment(undefined);
                        setInpLabel(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={updateSegment}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group mt-3 mb-4">
                    <label>Label</label>
                    <Input
                        placeholder="Enter Label"
                        value={inpLabel}
                        onChange={(e: any) => setInpLabel(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                title={`Delete Building`}
                className="admin-panel"
                open={modalDelete}
                closable={false}
                width={360}
                footer={[
                    <Button onClick={() => {
                        setModalDelete(false);
                        setSegment(undefined);
                        setInpLabel(undefined);
                    }}>
                        <i className="fa fa-times"/> &nbsp; Cancel
                    </Button>,
                    <Button type="primary" onClick={deleteSegment}>
                        Submit &nbsp;
                        <i className="fa fa-arrow-right"/>
                    </Button>,
                ]}
            >
                <div className="form-group">
                    Are you sure to delete <strong>"{segment?.label}"</strong> building ?
                    <br/>
                    <br/>
                    This action can't be undone. All your rooms will be deleted & devices
                    will be unassigned.
                </div>
            </Modal>
        </div>
    );
};
export default AdminSectionSegments;
