import {create} from "zustand";
import {Segment} from "../types/segment";
import {JsonDeepCopy} from "../utils/common";
import {GetSegments} from "../services/segment";

interface SegmentState {
    data: any[];
    modal: boolean;
    setModal: (selector: boolean) => void;
    selected: Segment | null;
    setSelected: (selected: Segment | null) => void;
    fetch: () => void;
    reset: () => void;
}

export const useSegmentStore = create<SegmentState>((set, get) => ({
    data: [],
    modal: true,
    setModal: (modal: boolean) => set({modal}),
    selected: null,
    setSelected: (selected: Segment | null) =>
        set({selected: JsonDeepCopy(selected)}),
    fetch: async () => {
        const res: any = await GetSegments();
        if (res.data) {
            set({data: res.data});
        }
    },
    reset: () => {
        set({data: [], selected: null});
        get().fetch();
    },
}));
