import {useEffect} from "react";
import Login from "./login";
import {ValidateUserTokenService} from "../../../services/user";
import Dashboard from "../dashboard";
import {useUserStore} from "../../../stores/useUserStore";
import {useSegmentStore} from "../../../stores/useSegmentStore";
import {User} from "../../../types/user";
import {useScreenStore} from "../../../stores/useScreenStore";

const LoginHandler: React.FC = () => {
    const setScreen = useScreenStore((state) => state.setScreen);
    const setUser = useUserStore((state) => state.setUser);

    const status = useUserStore((state) => state.status);
    const setStatus = useUserStore((state) => state.setStatus);

    const getSegments = useSegmentStore((state) => state.fetch);

    useEffect(() => {
        setupUserData();
    }, []);

    const setupUserData = async () => {
        if (localStorage.getItem("token")) {
            const currUser = await ValidateUserTokenService();
            if (currUser && currUser.userId && currUser.client && currUser.client.id) {
                const user: User = {
                    id: currUser.userId,
                    client: {
                        id: currUser.client.id,
                        label: currUser.name,
                    },
                    type: currUser.level === '10' ? 'admin' : 'user'
                }
                if (user.type === "admin") {
                    setScreen("admin");
                }
                setUser(user);
                setStatus("success");
                getSegments();
            } else {
                setStatus("failure");
            }
        } else {
            setStatus("failure");
        }
    };

    return (
        <>
            {status !== "success" && <Login/>}
            {status === "success" && <Dashboard/>}
        </>
    );
};
export default LoginHandler;
