import {v4 as uuidv4} from "uuid";
import {Md5} from "ts-md5";

export const JsonDeepCopy = (d: any) => {
    return JSON.parse(JSON.stringify(d));
};

export const UniqueID = (): string => uuidv4();

export const HashMd5 = (txt: string): string => Md5.hashStr(txt);
