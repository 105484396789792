import {create} from "zustand";

interface TrendState {
    data: any[];
    range?: { from: string, upto: string };
    setRange: (range: { from: string, upto: string }) => void;
}

export const useTrendStore = create<TrendState>((set, get) => ({
    data: [],
    setRange: (range: { from: string, upto: string }) => set({range}),
}));
