import React from "react";

import Greeting from "../../atoms/greeting";
import GroupSelector from "../../molecules/groupSelector";
import Devices from "../../molecules/devices";

const SegmentContent: React.FC = () => {
    return (
        <div className="col-md-12 col-lg-12 col-xl-11">
            <div className="content-wrapper">
                <Greeting/>
                <GroupSelector/>
                <Devices/>
            </div>
        </div>
    );
};

export default SegmentContent;
