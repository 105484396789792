import React from "react";
import {useUserStore} from "../../stores/useUserStore";
import {useSegmentStore} from "../../stores/useSegmentStore";
import {useScreenStore} from "../../stores/useScreenStore";
import SegmentControl from "../molecules/segmentControl";

const Greeting: React.FC = () => {
    const screen = useScreenStore((state) => state.screen);
    const user = useUserStore((state) => state.user);
    const segment = useSegmentStore((state) => state.selected);
    const showSegment = useSegmentStore((state) => state.setModal);

    return (
        <div className="col-12 header-wrapper">
            <div className="header">
                Hello, <span>{user?.client.label}</span>
            </div>
            {screen === "segments" && segment && (
                <>
                    <div>Your devices are under control</div>
                    <div className="float-right">
                        <SegmentControl/>
                    </div>
                </>
            )}
        </div>
    );
};

export default Greeting;
