import React, {useEffect} from "react";
import Navbar from "../../molecules/navbar";
import {useScreenStore} from "../../../stores/useScreenStore";
import Trends from "../../organisms/trends";
import {useWebSocketStore} from "../../../stores/useWebSocketStore";
import {WebSocketConnection} from "../../../utils/websocket";
import ApiConfigs from "../../../configs/api";
import {WebSocketMessage} from "../../../types/websocket";
import {JsonDeepCopy} from "../../../utils/common";
import Segments from "../../organisms/segment";
import AdminPanel from "../../organisms/admin";
import {useUserStore} from "../../../stores/useUserStore";
import {useDeviceStore} from "../../../stores/useDeviceStore";

const Dashboard: React.FC = () => {
    const user = useUserStore((state) => state.user);
    const getToken = useUserStore((state) => state.getToken);
    const screen = useScreenStore((state) => state.screen);
    const setWebSocketConnection = useWebSocketStore(
        (state) => state.setConnection
    );
    const setWebSocketMessage = useWebSocketStore((state) => state.setMessage);
    const setDeviceData = useDeviceStore((state) => state.setDeviceData);

    useEffect(() => {
        const token = getToken();
        if (token) {
            new WebSocketConnection({
                url: ApiConfigs.WEBSOCKET_SERVER_URL + "?token=" + token,
                on: {
                    open: (instance: WebSocketConnection) => {
                        console.log("WebSocket connection established");
                        setWebSocketConnection(instance);
                        /*
                        instance.send('device', {
                          type: 'device',
                          params: {
                            type: 'list',
                          }
                        });
                        */
                    },
                    message: (topic: string, payload: any) => {
                        console.log(topic, payload);
                        const wsMessage: WebSocketMessage = JsonDeepCopy({topic, payload});
                        setWebSocketMessage(wsMessage);
                        if (wsMessage.payload && wsMessage.payload.type === 'device') {
                            setDeviceData(wsMessage);
                        }
                    },
                },
            });
        }
    }, []);

    return (
        <>
            <Navbar/>
            {screen === "trends" && <Trends/>}
            {screen === "segments" && <Segments/>}
            {screen === "admin" && <AdminPanel/>}
        </>
    );
};
export default Dashboard;
