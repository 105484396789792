import {Modal} from "antd";
import React, {useEffect, useState} from "react";
import {useDeviceOptionStore} from "../../../stores/useDeviceOptionStore";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {GetDeviceData} from "../../../services/device";
import {useDeviceStore} from "../../../stores/useDeviceStore";
import {ApiResponse} from "../../../types/api";

type TrendDataItemType = { time: string, Temperature: number };

const Constants = {
    TREND_KEYS: {
        x: 'time',
        y: 'Temperature'
    },
    TREND_DATA_MAX_LEN: 100
}
export const DeviceTrend = () => {


    const TREND_KEYS = {
        x: 'time',
        y: 'Temperature'
    };

    const TREND_DATA_MAX_LEN = 100;

    const device = useDeviceStore(state => state.selected);
    const modal = useDeviceOptionStore(state => state.trendModal);
    const setModal = useDeviceOptionStore(state => state.setTrendModal);

    const [trendData, setTrendData] = useState<TrendDataItemType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setTrendData([]);
        if (modal && device) initializeData(device.id).then(r => {
        });
    }, [modal]);

    const initializeData = async (deviceId: string) => {
        try {
            const rangeFrom = new Date();
            const rangeUpto = new Date();
            rangeUpto.setDate(rangeUpto.getDate() + 1);
            rangeFrom.setDate(rangeFrom.getDate() - 15);

            const res: ApiResponse = await GetDeviceData(deviceId, {
                from: rangeFrom.toISOString().split('T')[0],
                upto: rangeUpto.toISOString().split('T')[0],
            });

            const resHistory: { date: string; params: { temperature: number } }[] = res.data.history;
            const trendDataObject: { [key: string]: TrendDataItemType } = {};

            const currTrendData: TrendDataItemType[] = [];


            let currDate = '';
            resHistory.forEach(d => {
                const dd = d.date.split('T');
                const date = dd[0];
                const tt = dd[1].split(':');
                const time = tt[0] + ':' + tt[1];

                const trendDataKey = date + '__' + time;
                const trendDataItem: TrendDataItemType = {
                    time: date + ' ' + time,
                    Temperature: d.params.temperature
                };
                if (currDate === '') {
                    currDate = date;
                } else if (currDate !== date) {
                    //trendDataItem.time = date;
                    currDate = date;
                }
                if (!trendDataObject[trendDataKey]) {
                    trendDataObject[trendDataKey] = trendDataItem;
                    if ((currTrendData.length + 1) <= Constants.TREND_DATA_MAX_LEN) currTrendData.push(trendDataItem);
                }
            });
            setTrendData(currTrendData.reverse());
        } catch (e) {
        }
    };

    return (
        <Modal
            title={`Device: ${device && (device.label)}`}
            open={modal}
            closable={true}
            onCancel={() => setModal(false)}
            footer={[]}
            width={'95%'}
        >
            <div className="form-group" style={{height: '70vh'}}>
                <ResponsiveContainer>
                    <LineChart data={trendData} defaultShowTooltip={true}>
                        <XAxis dataKey={Constants.TREND_KEYS.x}/>
                        <YAxis/>
                        <Line
                            type="monotone"
                            dataKey={Constants.TREND_KEYS.y}
                            stroke="#8884d8"
                            //strokeDasharray="5 5"
                        />
                        <CartesianGrid stroke="#bbb" strokeDasharray="5 5"/>
                        <Tooltip/>
                        <Legend/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </Modal>
    );
}
