import axios, {AxiosError, AxiosResponse} from "axios";
import ApiConfigs from "../../configs/api";
import {ApiResponse} from "../../types/api";

export const AdminGetSegments = async (): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .get(ApiConfigs.CORE_SERVER_URL + "/segment", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminAddSegment = async (reqBody: {
    label: string;
}): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .post(ApiConfigs.CORE_SERVER_URL + "/segment", reqBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminUpdateSegment = async (
    id: string,
    reqBody: { label: string }
): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .put(ApiConfigs.CORE_SERVER_URL + "/segment/" + id, reqBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminDeleteSegment = async (id: string): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .delete(ApiConfigs.CORE_SERVER_URL + "/segment/" + id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
