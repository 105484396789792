import React from "react";
import BtnRound from "../atoms/buttons/round";
import {Group} from "../../types/group";
import {useGroupStore} from "../../stores/useGroupStore";
import {useSegmentStore} from "../../stores/useSegmentStore";
import {useDeviceStore} from "../../stores/useDeviceStore";

const GroupSelector: React.FC = () => {
    const segment = useSegmentStore((state) => state.selected);

    const data = useGroupStore((state) => state.data);
    const selected = useGroupStore((state) => state.selected);
    const setSelected = useGroupStore((state) => state.setSelected);

    const fetchDevices = useDeviceStore((state) => state.fetch);

    return (
        segment && (
            <div className="col-12 room-wrapper">
                {data.length > 0 && (
                    <div style={{float: "left", width: "max-content", padding: "10px"}}>
                        {data.map((currGroup: Group, selectedIndex: number) => {
                            if (!selected && selectedIndex === 0) setSelected(currGroup);
                            return (
                                <BtnRound
                                    label={currGroup.label}
                                    selected={selected?.id === currGroup.id}
                                    onClick={() => {
                                        setSelected(currGroup);
                                        fetchDevices(segment.id, currGroup.id);
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
                {data.length < 1 && (
                    <div>
                        <div className="alert">
                            <i className="fa fa-frown-o"/>
                            &nbsp; No group / room inside{" "}
                            <strong>
                                <q>{segment.label}</q>
                            </strong>{" "}
                            building.
                        </div>
                    </div>
                )}
            </div>
        )
    );
};

export default GroupSelector;
