import {create} from "zustand";
import {WebSocketMessage} from "../types/websocket";
import {WebSocketConnection} from "../utils/websocket";

interface WebSocketState {
    connection: WebSocketConnection | undefined;
    setConnection: (connection: WebSocketConnection | undefined) => void;
    message: WebSocketMessage | undefined;
    setMessage: (message: WebSocketMessage) => void;
}

export const useWebSocketStore = create<WebSocketState>((set, get) => ({
    connection: undefined,
    setConnection: (connection: WebSocketConnection | undefined) => {
        if (get().connection) return;
        set({connection});
    },
    message: undefined,
    setMessage: (message: WebSocketMessage) => set({message}),
}));
