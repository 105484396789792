import axios, {AxiosError, AxiosResponse} from "axios";
import ApiConfigs from "../../configs/api";
import {ApiResponse} from "../../types/api";
import {DeviceConfigUpdateReq} from "../../types/web/requests/device";

export const AdminGetDevices = async (): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .get(`${ApiConfigs.CORE_SERVER_URL}/device`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminUpdateDevice = async (
    id: string,
    reqBody: { label: string; newSegmentId: string; newGroupId: string }
): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .put(ApiConfigs.CORE_SERVER_URL + "/device/" + id, reqBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};

export const AdminUpdateDeviceConfigs = async (
    id: string,
    reqBody: DeviceConfigUpdateReq
): Promise<ApiResponse> => {
    const token = localStorage.getItem("token");
    return axios
        .put(
            ApiConfigs.CORE_SERVER_URL + "/device/" + id + "/config",
            reqBody,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((errorRes: AxiosError) => {
            return errorRes.response?.data;
        });
};
