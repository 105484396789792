export enum DeviceNodeTypes {
    DIMMER = 'dimmer',
    SWITCH = 'switch',
}

export enum DeviceNodeAttrs {
    SW_1 = 'sw_1',
    SW_2 = 'sw_2',
    SW_3 = 'sw_3',
    SW_4 = 'sw_4',
    SW_5 = 'sw_5',
    SW_6 = 'sw_6',
    SW_7 = 'sw_7',
    SW_8 = 'sw_8',
    SW_9 = 'sw_9',
    SW_10 = 'sw_10',
    DM_1 = 'dm_1',
    DM_2 = 'dm_2',
    DM_3 = 'dm_3',
    DM_4 = 'dm_4',
    DM_5 = 'dm_5'
}

export interface DeviceNode {
    id: string;
    deviceId: string;
    label: string;
    type: DeviceNodeTypes;
    attr: string;
    params: {
        frequency?: string;
    }
}
